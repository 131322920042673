<template>
    <el-form ref="clientInfo" class="card-tail" label-position="top" v-loading="$waiting.is('loading')">
        <div class="p-5 flex flex-row justify-between">
            <div class="flex flex-row">
                <CompanyForm :client-info="clientInfo" :financial-years="financialYears" :financialyear-range="financialyearRange" :yes-no-has-auditor="yesNoHasAuditor" />
                <AuditForm :client-info="clientInfo" :audit-data="auditData" :select-auditor-title="selectAuditorTitle" :signature-text-auditor="signatureTextAuditor" />
            </div>
            <div>
                <el-button @click="modalVisible = true" type="default" plain class="p-1 ml-3">
                    <i class="fa-solid fa-arrows-rotate cursor-pointer" title="Uppdatera företagsuppgifter" />
                </el-button>
                <el-dialog :visible.sync="modalVisible" width="25%" title="Uppdatera">
                    <div class="flex flex-col space-y-2">
                        <h1 class="font-bold">Är du säker att du vill uppdatera företagsuppgifter?</h1>
                        <div class="flex flex-row justify-end">
                            <el-button @click="resetAccount" type="success">Uppdatera</el-button>
                            <el-button @click="modalVisible = false" type="warning">Avbryt</el-button>
                        </div>
                    </div>
                </el-dialog>
            </div>
        </div>
        <ExecutiveForm
            :executive-person="executivePerson"
            :executive-company="executiveCompany"
            :select-executive-role="selectExecutiveRole"
            :yes-no-executive="yesNoExecutive"
            :yes-no-executive-company="yesNoExecutiveCompany"
            :yes-no-signature="yesNoSignature"
        />
        <div class="p-5 flex justify-end w-full">
            <el-button size="medium" type="warning">Utkast Årsredovisning</el-button>
            <el-button size="medium" type="success" @click="updateCompanyInformation">Spara</el-button>
            <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
        </div>
    </el-form>
</template>

<script>
import Api from "../annualReport.api";
import Api2 from "../../lopande/lopande.api";

export default {
    name: "BalanceSheet",
    components: {
        CompanyForm: () => import("./components/CompanyForm.vue"),
        AuditForm: () => import("./components/AuditForm.vue"),
        ExecutiveForm: () => import("./components/ExecutiveForm.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        clientIdProp: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            clientId: null,
            clientInfo: {},
            companyData: {},
            auditData: {},
            executiveData: {},
            executivePerson: [],
            executiveCompany: [],
            financialYears: [],
            selectedFinancialYear: "",
            yesNoHasAuditor: [
                { id: 0, value: "Ja" },
                { id: 1, value: "Nej" },
            ],
            yesNoExecutive: [
                { id: 0, value: "Ja" },
                { id: 1, value: "Nej" },
            ],
            yesNoExecutiveCompany: [
                { id: 0, value: "Ja" },
                { id: 1, value: "Nej" },
            ],
            selectAuditorTitle: [
                { id: 0, name: "Auktoriserad revisor" },
                { id: 1, name: "Godkänd revisor" },
                { id: 2, name: "Revisor" },
            ],
            signatureTextAuditor: [
                { id: 0, value: "Min revisionsberättelse har lämnats..." },
                { id: 1, value: "Vår revisionsberättelse har lämnats..." },
            ],
            selectExecutiveRole: [
                { id: 0, name: "Styrelseledamot" },
                { id: 1, name: "VD" },
                { id: 2, name: "Styrelseordförande" },
                { id: 3, name: "Styrelseledamot och VD" },
                { id: 4, name: "Styrelseordförande och VD" },
                { id: 5, name: "Suppleant" },
            ],
            modalVisible: false,
            yesNoSignature: [
                { id: true, value: "Ja" },
                { id: false, value: "Nej" },
            ],
        };
    },

    async created() {
        this.clientId = this.clientIdProp;
        await this.getCompanyInformation();
        await this.getFinancialYears();
    },

    methods: {
        async getCompanyInformation() {
            this.$waiting.start("loading");
            this.companyData = await Api.getCompanyInfo(this.clientId, this.annualReportId);
            const clientDetails = this.companyData?.clientCompanyDetails;
            if (clientDetails) {
                this.clientInfo = clientDetails;
                this.selectedFinancialYear = `${clientDetails.financialYearStart} - ${clientDetails.financialYearEnd}`;
                this.$emit("selectedFinancialYear", this.selectedFinancialYear);
            } else {
                await this.createCompanyInformation();
            }

            if (this.companyData?.auditDetails) {
                this.auditData = this.companyData.auditDetails;
            } else {
                await this.createCompanyInformation();
            }

            const executiveDetails = this.companyData?.executiveDetails;
            if (executiveDetails) {
                this.executiveData = executiveDetails;
                this.executivePerson = executiveDetails.executivePerson.length > 0 ? executiveDetails.executivePerson : [];
                this.executiveCompany = executiveDetails.executiveCompany.length > 0 ? executiveDetails.executiveCompany : [];
            } else {
                await this.createCompanyInformation();
            }
            this.$waiting.end("loading");
        },
        async createCompanyInformation() {
            await Api.createCompanyInfo(this.clientId, this.annualReportId);
            await this.getCompanyInformation();
        },
        async getFinancialYears() {
            this.financialYears = await Api2.getRakenskapsar({ clientId: this.clientId });
        },
        async updateCompanyInformation() {
            this.$waiting.start("loading");
            const updateCompanyData = {
                clientCompanyDetails: this.clientInfo,
                auditDetails: this.auditData,
                executiveDetails: {
                    executivePerson: this.executivePerson,
                    executiveCompany: this.executiveCompany,
                },
            };
            await Api.updateCompanyInfo(this.annualReportId, updateCompanyData);
            this.$notify.success({ title: "Sparat företagsuppgifter" });
            this.$waiting.end("loading");
        },
        goToNextPage() {
            this.$router.push(`/annualReport/managementReport/${this.annualReportId}`);
        },
        async resetAccount() {
            this.modalVisible = false;
            this.$waiting.start("loading");
            await this.createCompanyInformation();
            this.$waiting.end("loading");
            this.$notify.success({ title: "Uppdaterat företagsuppgifter" });
        },
    },
    computed: {
        financialyearRange() {
            if (this.clientInfo) {
                return `${this.clientInfo.financialYearStart} - ${this.clientInfo.financialYearEnd}`;
            }
            return "";
        },
    },
};
</script>
